import { USER_ROLE } from '../../constants';

export const menuData = [
    {
        label: 'Admin Center',
        Icon: 'admin-center',
        to: '/admin',
        parentLabel: 'Admin Center',
        roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.CLIENT_ADMIN],
        level: 0,
        children: [
            {
                label: 'Cohort Management',
                Icon: 'cohort-management',
                to: '/cohort-management',
                level: 1,
            },
            {
                label: 'Company Management',
                Icon: 'company-management',
                to: '/company-management',
                level: 1,
            },
            {
                label: 'Content Management',
                Icon: 'content-management',
                to: '/content-management',
                roles: [USER_ROLE.SYSTEM_ADMIN],
                level: 1,
            },
            {
                label: 'System Notifications',
                Icon: 'system-notifications',
                to: '/system-notifications',
                level: 1,
            },
            {
                label: 'User Management',
                Icon: 'user-management',
                to: '/user-management',
                lastElement: true,
                level: 1,
            },
        ],
    },
    {
        label: 'Home',
        Icon: 'home',
        to: '/',
        parentLabel: 'Home',
        level: 0,
    },
    {
        label: 'Quality Management',
        Icon: 'quality-management',
        to: '/quality-management',
        parentLabel: 'Quality Management',
        level: 0,
        children: [
            {
                label: 'Hospital',
                Icon: 'hospital',
                to: '/hospital',
                level: 1,
                children: [
                    {
                        label: 'Star Explorer',
                        Icon: 'star-explorer',
                        to: '/star-explorer',
                        level: 2,
                        children: [
                            {
                                label: 'Nation Dashboard',
                                Icon: 'nation-dashboard',
                                to: '/national-dashboard',
                                level: 3,
                            },
                            {
                                label: 'State Overview',
                                Icon: 'state-overview',
                                to: '/state-overview',
                                level: 3,
                                children: [
                                    {
                                        label: 'State Detail',
                                        Icon: 'state-detail',
                                        to: '/state-detail',
                                        level: 4,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Star Report Card',
                        Icon: 'star-report',
                        to: '/star-report',
                        level: 2,
                        children: [
                            {
                                label: 'Mortality',
                                Icon: 'mortality',
                                to: '/mortality',
                                level: 3,
                            },
                            {
                                label: 'Readmission',
                                Icon: 'readmission',
                                to: '/readmission',
                                level: 3,
                            },
                            {
                                label: 'Safety',
                                Icon: 'safety',
                                to: '/safety',
                                level: 3,
                            },
                            {
                                label: 'Patient Experience',
                                Icon: 'patient-experience',
                                to: '/patient-exp',
                                level: 3,
                            },
                            {
                                label: 'Timely',
                                Icon: 'timely',
                                to: '/timely',
                                level: 3,
                            },
                            {
                                label: 'Multi Facility',
                                Icon: 'multi-facility',
                                to: '/multi-facility',
                                level: 3,
                            },
                        ],
                    },
                    {
                        label: 'Star Planner',
                        Icon: 'star-planner',
                        to: '/star-planner',
                        level: 2,
                    },
                    {
                        label: 'PH Insights',
                        Icon: 'ph-insights',
                        to: '/ph-insights',
                        disabled: true,
                        level: 2,
                    },
                ],
            },
            {
                label: 'Payer',
                Icon: 'payer',
                to: '/payer',
                level: 1,
            },
            {
                label: 'SNF',
                Icon: 'snf',
                to: '/snf',
                lastElement: true,
                level: 1,
            },
        ],
    },
    {
        label: 'Member Analytics',
        Icon: 'member-analytics',
        to: '/member-analytics',
        parentLabel: 'Member Analytics',
        level: 0,
        children: [
            {
                label: 'Population Characterization',
                Icon: 'population-characterization',
                to: '/population-characterization',
                level: 1,
            },
            {
                label: 'Retention Risk',
                Icon: 'retention-risk',
                to: '/retention-risk',
                children: [
                    {
                        label: 'Risk Reports',
                        Icon: 'risk-reports',
                        to: '/risk-report',
                        level: 2,
                    },
                    {
                        label: 'Risk Explorer',
                        Icon: 'risk-explorer',
                        to: '/risk-explorer',
                        level: 2,
                        children: [
                            {
                                label: 'Cohort View',
                                Icon: 'cohort-view',
                                to: '/cohort-view',
                                level: 3,
                            },
                            {
                                label: 'Company View',
                                Icon: 'company-view',
                                to: '/company-view',
                                level: 3,
                            },
                        ],
                    },
                    {
                        label: 'Risk Monitor',
                        Icon: 'risk-monitoring',
                        to: '/risk-monitor',
                        level: 2,
                        children: [
                            {
                                label: 'Enrollment',
                                Icon: 'enrollment',
                                to: '/enrollment',
                                disabled: true,
                                level: 3,
                            },
                            {
                                label: 'Alert Management',
                                Icon: 'alert-management',
                                to: '/alert-management',
                                level: 3,
                            },
                        ],
                    },
                    {
                        label: 'Risk Manager',
                        Icon: 'risk-manager',
                        to: '/risk-manager',
                        level: 2,
                        children: [
                            {
                                label: 'Cohort View',
                                Icon: 'cohort-view',
                                to: '/cohort-view',
                                level: 3,
                            },
                            {
                                label: 'Company View',
                                Icon: 'company-view',
                                to: '/company-view',
                                level: 3,
                            },
                        ],
                    },
                ],
                level: 1,
            },
            {
                label: 'Health Equity',
                Icon: 'health-equity',
                to: '/health-equity',
                disabled: true,
                lastElement: true,
                level: 1,
            },
        ],
    },
    {
        label: 'Advisory Group Management',
        Icon: 'advisory-group-management',
        to: '/advisory-group-management',
        parentLabel: 'Advisory Group Management',
        level: 0,
        children: [
            {
                label: 'Analytics',
                Icon: 'analytics',
                to: '/analytics',
                children: [
                    {
                        label: 'Population Characterization',
                        Icon: 'population-characterization',
                        to: '/population-characterization',
                        level: 2,
                    },
                    {
                        label: 'AG Representativeness',
                        Icon: 'advisory-group-representatives',
                        to: '/representativeness',
                        level: 2,
                    },
                    {
                        label: 'Health Equity',
                        Icon: 'health-equity',
                        to: '/health-equity',
                        level: 2,
                    },
                    {
                        label: 'CAHPS Assessment',
                        Icon: 'market-assment',
                        to: '/cahps-assessment',
                        level: 2,
                    },
                ],
                level: 1,
            },
            {
                label: 'Document Management',
                Icon: 'document-management',
                to: '/document-management',
                level: 1,
            },
            {
                label: 'Operations',
                Icon: 'operations',
                to: '/operations',
                level: 1,
                children: [
                    {
                        label: 'Master Meeting Schedule',
                        Icon: 'meeting-schedule',
                        level: 2,
                        to: '/master-meeting-schedule',
                    },
                    {
                        label: 'Support Staff',
                        Icon: 'roster',
                        level: 2,
                        to: '/support-staff',
                    },
                ],
            },
            {
                label: 'Program Management',
                Icon: 'program-management',
                to: '/program-management',
                level: 1,
            },
            {
                label: 'Recruitment',
                Icon: 'recruitment',
                to: '/recruitment',
                level: 1,
                children: [
                    {
                        label: 'Application Detail',
                        Icon: 'application-detail',
                        to: '/application-detail',
                        level: 2,
                    },
                    {
                        label: 'Panel Selection',
                        Icon: 'panel-selection1',
                        to: '/panel-selection',
                        level: 2,
                    },
                    {
                        label: 'Recruiting Outreach',
                        Icon: 'recruiting-outreach',
                        to: '/recruiting-outreach',
                        level: 2,
                    },
                    {
                        label: 'Panel Maintenance',
                        Icon: 'panel-maintenance',
                        to: '/panel-maintenance',
                        level: 2,
                    },
                    {
                        label: 'Recruiting Analytics',
                        Icon: 'recruiting-analytics',
                        to: '/recruiting-analytics',
                        level: 2,
                        children: [
                            {
                                label: 'AG View',
                                Icon: 'advisory-group-view',
                                to: '/ag-view',
                                level: 3,
                            },
                            {
                                label: 'Company View',
                                Icon: 'company-view',
                                level: 3,
                                to: '/company-view',
                            }
                        ],
                    },
                ],
            },
            {
                label: 'Regulatory Reporting',
                Icon: 'regulatory-reporting',
                to: '/regulatory-reporting',
                disabled: true,
                lastElement: true,
                level: 1,
            },
        ]
    },
    {
        label: 'Market Assessment',
        Icon: 'market-assment',
        to: '/market-assessment',
        parentLabel: 'Market Assessment',
        level: 0,
    },
    {
        label: 'Plan Design and Fit',
        Icon: 'plan-design-and-fit',
        to: '/plan-design',
        parentLabel: 'Plan Design and Fit',
        level: 0,
    }
];

export default menuData;

import React, { useContext, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import GridCellRating from "../../components/ui/GridCellRating";
import DataGrid from "../../components/DataGrid";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import { useDispatch, useSelector } from "react-redux";
import { getAdvisoryGroupRepresentativeDetailsData, resetRepresentativeDetails } from "../../ducks/advisoryGroup";
import Loader from "../../components/ui/Loader";
import styles from './RepresentativenessDetailsModal.module.scss';
import { toDecimal } from "../../utils/utils";

const RepresentativenessDetailsModal = ({ show, id, onCancel }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);

    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle: `RepresentativeNess-Details-${id}`,
        removeAfterPrint: true,
    });

    const repsDetailData = useSelector((state) => state.advisoryGroup.representativeDetails);

    const hideColumn = {
        category: false,
    };

    const columns = [
        {
            id: 'category',
            header: 'Category',
            accessorKey: 'category',
            enableSorting: false,
        },
        {
            id: 'categoryValue',
            header: 'Category',
            accessorKey: 'categoryValue',
            cell: ({ row }) => row.original.categoryValue,
            aggregatedCell: ({ row }) => (<div className="fw-bold">{row.original.category}</div>),
            enableSorting: false,
        },
        {
            id: 'populationPercent',
            header: 'Population %',
            accessorKey: 'populationPercent',
            cell: ({ row }) => toDecimal(row.original.populationPercent * 100),
            aggregatedCell: () => null,
            enableSorting: false,
        },
        {
            id: 'panelMembershipPercent',
            header: 'Panel Membership %',
            accessorKey: 'panelMembershipPercent',
            cell: ({ row }) => toDecimal(row.original.panelMembershipPercent * 100),
            aggregatedCell: () => null,
            enableSorting: false,
        },
        {
            id: 'difference',
            header: 'Difference',
            accessorKey: 'difference',
            cell: ({ row }) => toDecimal((row.original.populationPercent * 100) - (row.original.panelMembershipPercent * 100)),
            enableSorting: false,
        },
        {
            id: 'panelRating',
            header: 'Rating',
            accessorKey: 'panelRating',
            cell: ({ row }) => <GridCellRating rating={row.original.panelRating} customClass="my-1" />,
            enableSorting: false,
        },
    ];

    const handleModalClose = () => {
        dispatch(resetRepresentativeDetails());
        onCancel();
    }

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getAdvisoryGroupRepresentativeDetailsData(id));
        }
    }, [isAppLoaded, id]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleModalClose}
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Representativeness Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <div className="d-flex justify-content-end mb-3">
                        <Button
                            variant="primary"
                            onClick={() => {
                                handlePrint(null, () => contentRef.current);
                            }}>
                            Print
                        </Button>
                    </div>
                    <div>
                        {repsDetailData === null ? (
                            <Loader />
                        ) : (
                            <div
                                ref={contentRef}
                                className={styles.printableArea}>
                                <DataGrid
                                    data={repsDetailData}
                                    columns={columns}
                                    enableFilters={false}
                                    columnDisplay={hideColumn}
                                    groupBy={['category']}
                                    pagination={false}
                                    gridContainerClass="grid-with-scroll overflow-y-auto"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RepresentativenessDetailsModal;

import api from '../../services/api/programManagementService';
import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        GET_ALL_PROGRAMS: null,
        GET_ARCHIVED_PROGRAMS: null,
        ARCHIVE_PROGRAM: null,
        UPDATE_PROGRAM: null,
        COPY_PROGRAM: null,
        ADD_PROGRAM: null,
        AG_ROASTER_COMMENT: null,
        ADD_MEMBER_COMMENT: null,
        RESET_MEMBER_COMMENTS: null
    },
    'Program'
);

// Action Creators

export const getPrograms = (companyId) => dispatch => {

    api.getProgramData(companyId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_PROGRAMS,
            payload: data,
        });
    });
}

export const getProgramsByStatus = (companyId, status) => dispatch => {
    api.getProgramDataByStatus(companyId, status).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ARCHIVED_PROGRAMS,
            payload: data,
        });
    });
}

export const archiveProgram = (programData, successHandler) => dispatch => {
    api.archiveProgram(programData).then(() => {
        dispatch({
            type: actionTypes.ARCHIVE_PROGRAM,
            payload: programData,
        });
    });
    successHandler();
}

export const copyProgram = (programData, successHandler) => dispatch => {
    api.copyProgram(programData).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.COPY_PROGRAM,
            payload: data,
        });
        successHandler();
    });
}

export const createProgram = (programData, successHandler) => dispatch => {
    api.createProgram(programData).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.ADD_PROGRAM,
            payload: data,
        });
        successHandler();
    });
}

export const updateProgram = (programData, successHandler) => dispatch => {
    api.updateProgram(programData).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.UPDATE_PROGRAM,
            payload: data,
        });
        successHandler();
    });
}

export const resetMemberComments = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_MEMBER_COMMENTS
    });
}

// Reducer

export const initialState = {
    programs: [],
    archivedPrograms: [],
    rosterMemberCommentData: null,
    memberMeetingDetails: null,
    memberMeetingComments: null
};

export default function programManagementReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_ALL_PROGRAMS:
            return {
                ...state,
                programs: payload,
            };
        case actionTypes.GET_ARCHIVED_PROGRAMS:
            return {
                ...state,
                archivedPrograms: payload,
            };
        case actionTypes.ARCHIVE_PROGRAM: {
            const programsCopy = [...state.programs];
            const programIndex = programsCopy.findIndex(element => element.id === payload.id);

            if (programIndex !== -1) {
                const archivedProgram = { ...programsCopy[programIndex], status: payload.status };

                return {
                    ...state,
                    programs: programsCopy.filter(program => program.id !== payload.id),
                    archivedPrograms: [...state.archivedPrograms, archivedProgram],
                };
            }
            return state;
        }
        case actionTypes.COPY_PROGRAM: {
            let programsCopy = [...state.programs];
            const programObject = payload;
            programsCopy = [...programsCopy, ...programObject];
            return {
                ...state,
                programs: [...programsCopy]
            };
        }
        case actionTypes.ADD_PROGRAM: {
            let programsCopy = [...state.programs];
            const programObject = payload;
            programsCopy = [...programsCopy, ...programObject];
            return {
                ...state,
                programs: [...programsCopy]
            };
        }
        case actionTypes.UPDATE_PROGRAM: {
            let programsCopy = [...state.programs];
            const programIndex = programsCopy.findIndex(element => element.id === payload.id);
            programsCopy.splice(programIndex, 1);
            programsCopy[programIndex] = { ...payload };
            return {
                ...state,
                programs: [...programsCopy]
            };
        }
        case actionTypes.AG_ROASTER_COMMENT: {
            return {
                ...state,
                rosterMemberCommentData: [...payload]
            }
        }
        case actionTypes.RESET_MEMBER_COMMENTS:
            return {
                ...state,
                memberMeetingComments: { ...initialState.memberMeetingComments },
            };
        default:
            return state;
    }
};

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';
import withAuth from '../../../../components/hoc/withAuth';
import ActionBar from '../../../../components/ui/ActionBar';
import Icon from '../../../../components/ui/Icon';
import DataGrid from "../../../../components/DataGrid";
import Loader from "../../../../components/ui/Loader";
import OverflowText from "../../../../components/ui/OverflowTooltip";
import api from "../../../../services/api/advisoryGroupService";
import {
    getAdvisoryGroupPotentialMembers,
    getAdvisoryGroupSelectedMembers, removeAgMembers, resetPanelSelection,
    selectAgMembers, getAdvisoryGroupData, updateAdvisoryGroupStatus,
} from '../../../../ducks/advisoryGroup';
import styles from './PanelSelection.module.scss';
import gridStyles from './../../../../components/DataGrid.module.scss';
import { sortingFnDateOnly, toDate } from "../../../../utils/utils";
import { AG_RECRUITMENT_MEMBER_STATUS, ALERT_TYPE, AG_MEMBER_STATUS, REVISED_SCORE_TYPE, AG_STATUS } from '../../../../constants';
import { AlertContext } from "../../../../context/AlertContext";
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import messages from '../../../../utils/helper/messages';
import ConfirmModal from '../../../../components/ui/ConfirmModal';
import CollapsePanel from "../../../../components/ui/CollapsePanel";
import PanelScore from "./PanelScore";
import AgSelector from '../../../../components/ui/Selector';

const PanelSelection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { showAlert } = useContext(AlertContext);

    const isAppLoaded = useContext(AppLoadContext);

    const currentUser = useSelector((state) => state.user.currentUser);
    const advisoryGroups = useSelector(
        (state) => state.advisoryGroup.masterList
    );

    const selectedMembers = useSelector(
        (state) => state.advisoryGroup.panelSelectedMembers
    );
    const potentialMembers = useSelector(
        (state) => state.advisoryGroup.panelPotentialMembers
    );

    const [potentialMemberIds, setPotentialMemberIds] = useState([]);
    const [selectedMemberIds, setSelectedMemberIds] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [revisedRepScore, setRevisedRepScore] = useState(null);
    const [disableRevisedScoreBtn, setDisableRevisedScoreBtn] = useState(false);
    const [currentRepScore, setCurrentRepScore] = useState(null);
    const [selectedAgId, setSelectedAgId] = useState(0);
    const [state] = useState(location.state || {});
    const [updatedAgId, setUpdatedAgId] = useState(0);

    const agList = advisoryGroups?.filter(ag => ag.status === AG_STATUS.Active || ag.status === AG_STATUS.New);

    const selectedMembersRowActions = ({ row }) => {
        return (
            <div className="text-center">
                <label key={row.original.id}>
                    <input
                        type="checkbox"
                        checked={selectedMemberIds.includes(row.original.id)}
                        disabled={row.original.memberStatus !== AG_MEMBER_STATUS.Temporary}
                        onChange={() =>
                            handleSelectedCheckboxChange(row.original.id, event)
                        }
                    />
                </label>
            </div>
        );
    };

    const potentialMembersRowActions = ({ row }) => {
        return (
            <div className="text-center">
                <label key={row.original.id}>
                    <input
                        type="checkbox"
                        checked={potentialMemberIds.includes(row.original.id)}
                        onChange={() =>
                            handlePotentialCheckboxChange(
                                row.original.id,
                                event
                            )
                        }
                    />
                </label>
            </div>
        );
    };

    const handleSelectedCheckboxChange = (memberId, event) => {
        setPotentialMemberIds([]);
        if (event.target.checked) {
            if (!selectedMemberIds.includes(memberId)) {
                setSelectedMemberIds((prevIds) => [...prevIds, memberId]);
            }
        } else if (selectedMemberIds.includes(memberId)) {
            setSelectedMemberIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== memberId)
            );
        }
    };

    const handlePotentialCheckboxChange = (memberId, event) => {
        setSelectedMemberIds([]);
        if (event.target.checked) {
            if (!potentialMemberIds.includes(memberId)) {
                setPotentialMemberIds((prevIds) => [...prevIds, memberId]);
            }
        } else if (potentialMemberIds.includes(memberId)) {
            setPotentialMemberIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== memberId)
            );
        }
    };

    const showRosterCellInfo = ({ row, cell }) => {
        const { birthday, race, primaryLanguage, memberStatus } = row.original;
        return (
            <div className="d-flex justify-content-center">
                <OverflowText
                    id={cell.id}
                    tooltipStyle={styles.tooltip}
                    label={
                        <div className={`text-start ${styles.tooltip}`}>
                            DOB: {toDate(birthday)}
                            <br />
                            Race: {race}
                            <br />
                            Language: {primaryLanguage}
                            <br />
                            Status: {memberStatus}
                        </div>
                    }>
                    <Icon
                        icon="information"
                        size={14}
                    />
                </OverflowText>
            </div>
        );
    };

    const potentialMembersColumns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
        },
        {
            id: 'birthday',
            header: 'DOB',
            accessorFn: (row) => toDate(row.birthday),
            sortingFn: sortingFnDateOnly,
            sortType: 'date',
            filterFn: 'equalsString'
        },
        {
            id: 'race',
            header: 'Race',
            accessorKey: 'race',
        },
        {
            id: 'primaryLanguage',
            header: 'Language',
            accessorKey: 'primaryLanguage',
        },
        {
            id: 'memberStatus',
            header: 'Status',
            accessorKey: 'memberStatus',
        },
        {
            id: 'actions',
            header: 'Move',
            cell: potentialMembersRowActions,
            size: 50,
            minSize: 50,
            maxSize: 50,
        },
    ];

    const selectedMembersColumns = [
        {
            id: 'actions',
            header: 'Move',
            cell: selectedMembersRowActions,
            size: 50,
            minSize: 50,
            maxSize: 50,
        },
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
        },
        {
            id: 'memberStatus',
            header: 'Status',
            accessorKey: 'memberStatus',
        },
        {
            id: 'info',
            header: 'Info',
            cell: showRosterCellInfo,
        },
    ];

    const handleReviseScore = () => {
        setDisableRevisedScoreBtn(true);
        setIsLoading(true);
        api.GetRevisedRepScore(selectedAgId, { tempSelectedMember: newMembers }).then(
            (res) => {
                const score = res.data.repScores.find(score => score.type === REVISED_SCORE_TYPE.PanelScore);

                setRevisedRepScore(score.aggregateScoreNumeric);
                setIsLoading(false);
            }
        );
    };

    const saveDataRecords = async (memberData) => {
        api.UpdateRecruitmentMemberStatuses({ memberData: [...memberData], calculateRepScore: true, agId: selectedAgId });
    }

    const updateAgStatus = () => {
        const updateData = {
            id: selectedAgId,
            status: AG_STATUS.Active
        };
        dispatch(updateAdvisoryGroupStatus(updateData, () => {
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }, handleError));
    }

    const handleSave = () => {
        if (newMembers.length === 0) return;

        setIsLoading(true);
        let memberData = [];
        for (let newMember of newMembers) {
            memberData.push({
                memberId: newMember.id,
                memberStatus: AG_RECRUITMENT_MEMBER_STATUS.Selected,
            });
        }

        saveDataRecords(memberData).then(() => {
            handleSuccess();
            // check: if AG current status is New
            // action: update AG status to Active
            const selectedAg = agList.find(ag => ag.id === selectedAgId);
            if (selectedAg.status === AG_STATUS.New) {
                updateAgStatus();
            }
        }).catch(handleError);
        setSelectedAgId(0);
    };

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.updatePanelSelection, ALERT_TYPE.SUCCESS);
        resetPanelSelectionView();
        dispatch(getAdvisoryGroupSelectedMembers(selectedAgId));
        dispatch(getAdvisoryGroupPotentialMembers(selectedAgId));
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleCancel = () => {
        if (newMembers.length > 0) {
            setConfirmModal(true);
        } else {
            resetPanelSelectionView();
            setSelectedAgId(0);
        }
    };

    const resetPanelSelectionView = () => {
        setNewMembers([]);
        setSelectedMemberIds([]);
        setPotentialMemberIds([]);
        setCurrentRepScore(null);
        setRevisedRepScore(null);
        dispatch(resetPanelSelection());
    }

    // Add to ag members
    const handleMoveToSelectedMembers = () => {
        let potentialMembersCopy = [...potentialMembers];
        let membersToSelect = [];
        for (let member of potentialMembers) {
            if (potentialMemberIds.includes(member.id)) {
                const memberIndexToRemove = potentialMembersCopy.findIndex(el => el.id === member.id);
                membersToSelect.push({
                    ...member,
                    prevMemberStatus: member.memberStatus, // save current status to temp key to restore when moving back
                    memberStatus: AG_MEMBER_STATUS.Temporary
                });
                potentialMembersCopy.splice(memberIndexToRemove, 1);
            }
        }
        dispatch(selectAgMembers({ newMembers: membersToSelect, updatedPotentialMembers: potentialMembersCopy }));
        setPotentialMemberIds([]);
    };

    // Remove from ag members
    const handleMoveToPotentialMembers = () => {
        let selectedMembersCopy = [...selectedMembers];
        let membersToRemove = [];
        for (let member of selectedMembers) {
            if (selectedMemberIds.includes(member.id)) {
                const memberIndexToRemove = selectedMembersCopy.findIndex(el => el.id === member.id);
                const memberCopy = { ...member, memberStatus: member.prevMemberStatus ?? member.memberStatus }
                delete memberCopy['prevMemberStatus']; // restore to previous status and remove temp status
                membersToRemove.push(memberCopy);
                selectedMembersCopy.splice(memberIndexToRemove, 1);
            }
        }
        dispatch(removeAgMembers({ removedMembers: membersToRemove, updatedSelectedMembers: selectedMembersCopy }));
        setSelectedMemberIds([]);
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
    };

    const handleConfirm = () => {
        handleConfirmClose();
        showAlert(messages.success.noUpdatesPanelSelection, ALERT_TYPE.ERROR);
        resetPanelSelectionView();
        setSelectedAgId(updatedAgId !== 0 ? updatedAgId : 0);
    };

    const handleSelectChange = (e) => {
        if (newMembers.length > 0) {
            setUpdatedAgId(Number(e.target.value));
            setConfirmModal(true);
        } else {
            setSelectedAgId(Number(e.target.value));
        }
    };

    useEffect(() => {
        navigate(".", { replace: true });
    }, [navigate]);

    useEffect(() => {
        if (state && state.agId) {
            setSelectedAgId(state.agId);
        }
        selectedAgId === 0 && dispatch(resetPanelSelection());
    }, [location.pathname]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }
    }, [isAppLoaded, dispatch, currentUser]);

    useEffect(() => {
        if (selectedAgId && selectedAgId !== 0) {
            dispatch(getAdvisoryGroupSelectedMembers(selectedAgId));
            dispatch(getAdvisoryGroupPotentialMembers(selectedAgId));
            api.GetCurrentRepScore(selectedAgId, REVISED_SCORE_TYPE.PanelScore).then((res) => {
                setCurrentRepScore(res.data[0]?.aggregateScoreNumeric);
            });
        }
        setUpdatedAgId(0);
    }, [selectedAgId]);

    useEffect(() => {
        if (selectedMembers) {
            let tempMembers = selectedMembers.filter(
                (member) => member.memberStatus === AG_MEMBER_STATUS.Temporary
            );
            if (tempMembers.length > 0) {
                setNewMembers(tempMembers);
            } else {
                setNewMembers([]);
            }
        }
    }, [selectedMembers]);

    useEffect(() => {
        setDisableRevisedScoreBtn(false);
    }, [selectedMembers?.length]);

    return (
        <>
            <ActionBar back={true}>
                <AgSelector
                    items={agList}
                    param="shortName"
                    value={selectedAgId}
                    placeholder="Select Advisory Group"
                    noRecordMessage="No advisory groups"
                    onSelectChange={handleSelectChange}
                    wrapperStyle="w-auto"
                />
                {selectedAgId !== 0 && (
                    <>
                        <div className="align-items-center">
                            <div className="d-flex gap-4 justify-content-center">
                                <PanelScore
                                    label="Current Score"
                                    score={currentRepScore}
                                />
                                <PanelScore
                                    label="Revised Score"
                                    score={revisedRepScore}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-end">
                            <Button
                                variant="primary"
                                type="button"
                                disabled={
                                    isLoading ||
                                    disableRevisedScoreBtn ||
                                    newMembers.length === 0
                                }
                                onClick={handleReviseScore}>
                                Revise Score
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                disabled={
                                    isLoading ||
                                    newMembers.length === 0
                                }
                                onClick={handleSave}>
                                Save
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={handleCancel}>
                                Close
                            </Button>
                        </div>
                    </>
                )}
            </ActionBar>
            {selectedAgId === 0 ? (
                <div className="border border-1 rounded bg-white p-3 shadow-sm mb-3">
                    Select an advisory group below to proceed with{' '}
                    <mark className="fw-bold">Panel Selection</mark> process.
                </div>
            ) : (
                <>
                    <div className="px-3 py-2 mt-4">
                        <CollapsePanel
                            title="Instructions"
                            wrapperStyle="mx-n3 mt-n3">
                            <p className="lh-sm mb-0 small">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur.
                            </p>
                        </CollapsePanel>
                    </div>
                    <Row className="mt-4">
                        <Col
                            md={7}
                            className="position-relative">
                            <ActionBar
                                title="Advisory Group Candidates"
                                titleClass="fw-bold"
                            />
                            {potentialMembers === null ? (
                                <Loader />
                            ) : (
                                <DataGrid
                                    columns={potentialMembersColumns}
                                    data={potentialMembers}
                                    pagination={false}
                                    gridContainerClass="grid-with-scroll overflow-auto"
                                />
                            )}
                        </Col>
                        <Col
                            md={1}
                            className="px-0 d-flex justify-content-center align-self-center">
                            <div className="d-flex flex-column gap-2 mt-4">
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="d-flex justify-content-center align-items-center pe-2"
                                    disabled={potentialMemberIds.length === 0}
                                    onClick={handleMoveToSelectedMembers}>
                                    Move
                                    <Icon
                                        icon="arrow-up"
                                        size={12}
                                        className="rotate-90 ms-1"
                                    />
                                </Button>
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="d-flex justify-content-center align-items-center ps-2"
                                    disabled={selectedMemberIds.length === 0}
                                    onClick={handleMoveToPotentialMembers}>
                                    <Icon
                                        icon="arrow-down"
                                        size={12}
                                        className="rotate-90 me-1"
                                    />
                                    Move
                                </Button>
                            </div>
                        </Col>
                        <Col md={4}>
                            <ActionBar
                                title="Current Advisory Group Roster"
                                titleClass="fw-bold"
                            />
                            {selectedMembers === null ? (
                                <Loader />
                            ) : (
                                <DataGrid
                                    columns={selectedMembersColumns}
                                    data={selectedMembers}
                                    enableFilters={false}
                                    pagination={false}
                                    gridContainerClass={`grid-with-scroll overflow-auto ${gridStyles.hasFilters}`}
                                />
                            )}
                        </Col>
                    </Row>
                    <ConfirmModal
                        show={confirmModal}
                        message={messages.confirm.discardProgramEdit}
                        onConfirm={handleConfirm}
                        onCancel={handleConfirmClose}
                        onHideCallback={handleConfirmClose}
                    />
                </>
            )}
        </>
    );
};

export default withAuth(PanelSelection);

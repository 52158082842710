import api from "../../services/api/advisoryGroupService";
import keyMirror from "../../utils/keyMirror";
import { sortByDate } from "../../utils/utils";

export const actionTypes = keyMirror(
    {
        GET_ADVISORY_GROUP_MASTER_DATA: null,
        GET_ADVISORY_GROUP__DATA: null,
        GET_ADVISORY_GROUP_RECRUITMENT_DATA: null,
        GET_ADVISORY_GROUP_REPRESENTATIVE_DATA: null,
        GET_ADVISORY_GROUP_REPRESENTATIVE_DETAILS_DATA: null,
        GET_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA: null,
        ADD_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA: null,
        UPDATE_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA: null,
        RESET_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA: null,
        UPDATE_ADVISORY_GROUP_RECRUITMENT_MEMBER_DATA: null,
        GET_ADVISORY_GROUP_MASTER_SCHEDULE_DATA: null,
        GET_ADVISORY_GROUP_MEETING_DETAILS: null,
        UPDATE_ADVISORY_GROUP_MEETING_DETAILS: null,
        GET_ADVISORY_GROUP_MEETING_LOCATIONS: null,
        GET_ADVISORY_GROUP_MEETING_LOCATION: null,
        GET_ADVISORY_GROUP_MEETING_LOCATION_CONTRACTS: null,
        ADD_ADVISORY_GROUP_MEETING_LOCATION: null,
        UPDATE_ADVISORY_GROUP_MEETING_LOCATION: null,
        ARCHIVE_ADVISORY_GROUP_MEETING_LOCATION: null,
        GET_ADVISORY_GROUP_MEETING_MEMBERS: null,
        UPDATE_ADVISORY_GROUP_MEETING_STATUS: null,
        UPDATE_ADVISORY_GROUP_ATTENDANCE_POST_STATUS: null,
        UPDATE_ADVISORY_GROUP_PRE_RESPONSE_STATUS: null,
        RESET_LOCATION_AND_CONTRACT_DETAILS: null,
        GET_ADVISORY_GROUP_ANALYTICS_DATA: null,
        RESET_REPRESENTATIVE_DETAILS: null,
        ADD_ADVISORY_GROUP_MEETING_ATTENDANCE_LOG: null,
        UPDATE_ADVISORY_GROUP_MEETING_ATTENDANCE_LOG_DETAILS: null,
        GET_COMMENTS_BY_MEETING_ID: null,
        ADD_MEETING_COMMENT: null,
        RESET_MEETING_MEMBER_COMMENTS: null,
        GET_MEMBER_PORTAL_DATA: null,
        GET_ADVISORY_GROUP_PORTAL_DETAILS: null,
        ADD_ADVISORY_GROUP_PORTAL_DETAILS: null,
        UPDATE_ADVISORY_GROUP_PORTAL_DETAILS: null,
        RESET_PORTAL_DETAILS: null,
        GET_RECRUITING_OUTREACH_DATA: null,
        UPDATE_RECRUITING_OUTREACH_DATA: null,
        ARCHIVE_RECRUITMENT_MEMBERS: null,
        PROCESS_RECRUITMENT_APPLICATION: null,
        GET_MEMBER_COMMENTS: null,
        ADD_MEMBER_COMMENT: null,
        RESET_MEMBER_COMMENTS: null,
        RECRUITING_OUTREACH_COMMENT: null,
        DELETE_OUTREACH_COMMENT: null,
        RESET_RECRUITING_OUTREACH_DATA: null,
        GET_AG_SELECTED_MEMBERS: null,
        GET_AG_POTENTIAL_MEMBERS: null,
        SELECT_AG_MEMBERS: null,
        REMOVE_AG_MEMBERS: null,
        RESET_PANEL_SELECTION: null,
        UPDATE_ENROLLED_MEMBER_DATA: null,
        GET_AG_MEMBER_MEETING_DETAILS: null,
        UPDATE_ROSTER_MEMBER_DATA: null,
        UPDATE_ADVISORY_GROUP_STATUS: null,
        ADD_MEETING_DETAILS: null,
        GET_AG_MEMBER_MEETING_DATA: null,
        GET_AG_ROSTER_MEMBER_DATA: null
    },
    "AdvisoryGroup"
);

// Action Creators
export const getAdvisoryGroupData = (id) => dispatch => {
    api.GetAdvisoryGroupData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MASTER_DATA,
            payload: data
        });
    });
};

export const getAdvisoryGroupMasterData = (companyId, status) => dispatch => {
    api.GetAdvisoryGroupMasterData(companyId, status).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP__DATA,
            payload: data
        });
    });
};

export const getAdvisoryGroupRecruitmentData = (id) => dispatch => {
    api.GetAdvisoryGroupRecruitmentData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_RECRUITMENT_DATA,
            payload: data
        });
    });
}

export const getViewMemberPagesData = (id) => dispatch => {
    api.GetViewMemberPagesData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_MEMBER_PORTAL_DATA,
            payload: data
        });
    });
}

export const getAdvisoryGroupRepresentativeneData = (id) => dispatch => {
    api.GetAdvisoryGroupRepresentativeData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_REPRESENTATIVE_DATA,
            payload: data
        });
    });
}

export const getAdvisoryGroupRecruitmentApplicationData = (id) => dispatch => {
    api.GetAdvisoryGroupRecruitmentApplicationData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA,
            payload: data
        });
    });
}

export const addAdvisoryGroupRecruitmentApplicationData = (data, successHandler, errorHandler) => dispatch => {
    api.AddAdvisoryGroupRecruitmentApplicationData(data).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.ADD_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA,
            payload: data
        });
        successHandler();
    }).catch(function (error) {
        errorHandler(error);
    });
}

export const resetAdvisoryGroupRecruitmentApplicationData = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA
    });
}

export const getAdvisoryGroupRepresentativeDetailsData = (id) => dispatch => {
    api.GetAdvisoryGroupRepresentativeDetailsData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_REPRESENTATIVE_DETAILS_DATA,
            payload: data
        });
    });
}

export const updateAdvisoryGroupRecruitmentApplicationData = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateAdvisoryGroupRecruitmentApplicationData(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA,
            payload: data
        });
        successHandler();
    }).catch(function (error) {
        errorHandler(error);
    });
}

export const updateAdvisoryGroupRecruitmentMemberData = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateAdvisoryGroupRecruitmentMemberData(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_RECRUITMENT_MEMBER_DATA,
            payload: data
        });
        successHandler();
    }).catch(function (error) {
        errorHandler(error);
    });
}

export const getAdvisoryGroupMasterScheduleData = (id) => dispatch => {
    api.GetAdvisoryGroupMasterScheduleData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MASTER_SCHEDULE_DATA,
            payload: data
        });
    });
}

export const getAdvisoryGroupAnalyticsData = (id) => dispatch => {
    api.GetAdvisoryGroupAnalyticsData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_ANALYTICS_DATA,
            payload: data
        });
    });
}

export const getAdvisoryGroupMeetingDetails = (id) => dispatch => {
    api.GetAdvisoryGroupMeetingDetails(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MEETING_DETAILS,
            payload: data
        });
    });
}

export const updateAdvisoryGroupMeetingDetails = (data, successHandler) => dispatch => {
    api.UpdateAdvisoryGroupMeetingDetails(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_MEETING_DETAILS,
            payload: data
        });
        successHandler();
    });
}

export const getAdvisoryGroupMeetingLocations = (id) => dispatch => {
    api.GetMeetingLocationsData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MEETING_LOCATIONS,
            payload: data
        });
    });
}

export const getAdvisoryGroupMeetingMemberData = (id, meetingId) => dispatch => {
    api.GetMeetingMembersData(id, meetingId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MEETING_MEMBERS,
            payload: data
        });
    });
}

export const getAdvisoryGroupMeetingLocationById = (id, successHandler, errorHandler) => dispatch => {
    api.GetAdvisoryGroupMeetingLocationDetails(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MEETING_LOCATION,
            payload: data
        });
        successHandler();
    }).catch(function (error) {
        errorHandler(error);
    });
}

export const getAdvisoryGroupMeetingLocationContracts = (id) => dispatch => {
    api.GetContractFiles(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_MEETING_LOCATION_CONTRACTS,
            payload: {
                ...data,
                id: id,
            },
        });
    })
}

export const addAdvisoryGroupMeetingLocation = (data, successHandler, errorHandler) => dispatch => {
    api.AddMeetingLocation(data).then(() => {
        dispatch({
            type: actionTypes.ADD_ADVISORY_GROUP_MEETING_LOCATION,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const updateAdvisoryGroupMeetingLocation = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateMeetingLocation(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_MEETING_LOCATION,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const updateAttendancePostStatus = (data, successHandler) => dispatch => {
    api.UpdateMeetingPostStatus(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_ATTENDANCE_POST_STATUS,
            payload: data
        });
        successHandler();
    });
}

export const updatePreResponseStatus = (data, successHandler) => dispatch => {
    api.UpdateMeetingResponseStatus(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_PRE_RESPONSE_STATUS,
            payload: data
        });
        successHandler();
    });
}

export const updateMeetingStatus = (data, successHandler) => dispatch => {
    api.UpdateMeetingStatus(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_MEETING_STATUS,
            payload: data
        });
        successHandler();
    });
}

export const archiveMeetingLocation = (data, successHandler) => dispatch => {
    api.ArchiveMeetingLocation(data).then(() => {
        dispatch({
            type: actionTypes.ARCHIVE_ADVISORY_GROUP_MEETING_LOCATION,
            payload: data
        });
        successHandler();
    });
}

export const resetLocationAndContractDetails = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_LOCATION_AND_CONTRACT_DETAILS,
    });
}


export const resetRepresentativeDetails = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_REPRESENTATIVE_DETAILS,
    });
}

export const addAttendanceLogs = (data, successHandler, errorHandler) => dispatch => {
    api.AddAttendanceLogs(data).then(() => {
        dispatch({
            type: actionTypes.ADD_ADVISORY_GROUP_MEETING_ATTENDANCE_LOG,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const updateAttendanceLogDetails = (data, successHandler) => dispatch => {
    api.UpdateAttendanceLogDetails(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_MEETING_ATTENDANCE_LOG_DETAILS,
            payload: data
        });
        successHandler();
    });
}

export const getCommentsByMeetingId = (data) => dispatch => {
    api.GetCommentsByMeetingId(data).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_COMMENTS_BY_MEETING_ID,
            payload: data
        });
    });
}

export const addComment = (data, successHandler, errorHandler) => dispatch => {
    api.AddComment(data).then(() => {
        dispatch({
            type: actionTypes.ADD_MEETING_COMMENT,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const resetMeetingMemberComments = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_MEETING_MEMBER_COMMENTS,
    });
}

export const getPortalDetails = (id) => dispatch => {
    api.GetPortalDetailsByAgId(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ADVISORY_GROUP_PORTAL_DETAILS,
            payload: data,
        });
    });
}

export const addPortalDetails = (data, successHandler, errorHandler) => dispatch => {
    api.AddPortal(data).then(() => {
        dispatch({
            type: actionTypes.ADD_ADVISORY_GROUP_PORTAL_DETAILS,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const updatePortalDetails = (data, successHandler, errorHandler) => dispatch => {
    api.UpdatePortalDetails(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_PORTAL_DETAILS,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const resetPortalDetails = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_PORTAL_DETAILS,
    });
}

export const getRecruitingOutreachByAgId = (id) => dispatch => {
    api.GetRecruitingOutreachByAgId(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_RECRUITING_OUTREACH_DATA,
            payload: data,
        });
    });
}

export const updateRecruitingOutreach = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateRecruitingOutreach(data).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.UPDATE_RECRUITING_OUTREACH_DATA,
            payload: data,
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const archiveMemberComment = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateMemberCommentStatus(data).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.DELETE_OUTREACH_COMMENT,
            payload: data
        });
        successHandler();
    }).catch(function (error) {
        errorHandler(error);
    });
}

export const archiveRecruitmentMembers = (memberData, successHandler, errorHandler) => dispatch => {
    api.UpdateRecruitmentMemberStatuses({ memberData: [...memberData] }).then(() => {
        dispatch({
            type: actionTypes.ARCHIVE_RECRUITMENT_MEMBERS,
            payload: memberData,
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const processRecruitmentApplication = (data, successHandler, errorHandler) => dispatch => {
    api.ProcessRecruitmentApplication(data).then(() => {
        dispatch({
            type: actionTypes.PROCESS_RECRUITMENT_APPLICATION,
            payload: data,
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const getMemberComments = (memberId) => dispatch => {
    api.GetMemberCommentsByMemberId(memberId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_MEMBER_COMMENTS,
            payload: data
        });
    });
}

export const insertMemberComment = (data, successHandler, errorHandler) => dispatch => {
    api.InsertMemberComment(data).then(() => {
        dispatch({
            type: actionTypes.ADD_MEMBER_COMMENT,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const resetMemberComments = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_MEMBER_COMMENTS
    });
}

export const resetRecruitmentOutreachData = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_RECRUITING_OUTREACH_DATA
    });
}

export const getAdvisoryGroupSelectedMembers = (id) => dispatch => {
    api.GetAdvisoryGroupSelectedMembers(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_AG_SELECTED_MEMBERS,
            payload: data
        });
    });
}

export const getAdvisoryGroupPotentialMembers = (id) => dispatch => {
    api.GetAdvisoryGroupPotentialMembers(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_AG_POTENTIAL_MEMBERS,
            payload: data
        });
    });
}

export const selectAgMembers = (data) => dispatch => {
    dispatch({
        type: actionTypes.SELECT_AG_MEMBERS,
        payload: data
    });
}

export const removeAgMembers = (data) => dispatch => {
    dispatch({
        type: actionTypes.REMOVE_AG_MEMBERS,
        payload: data
    });
}

export const resetPanelSelection = (data) => dispatch => {
    dispatch({
        type: actionTypes.RESET_PANEL_SELECTION,
        payload: data
    });
}

export const updateEnrolledMemberData = (data) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_ENROLLED_MEMBER_DATA,
        payload: data
    });
}

export const getAgMemberMeetingDetails = (agMemberId) => dispatch => {
    api.GetAgMemberMeetingDetails(agMemberId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_AG_MEMBER_MEETING_DETAILS,
            payload: data
        });
    });
}

export const getMeetingDetailsByAgId = (agMemberId) => dispatch => {
    api.GetMeetingDetailsByAgId(agMemberId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_AG_MEMBER_MEETING_DATA,
            payload: data
        });
    });
}

export const updateAdvisoryGroupRosterMemberData = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateAdvisoryGroupRosterMemberData(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ROSTER_MEMBER_DATA,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const updateAdvisoryGroupStatus = (data, successHandler, errorHandler) => dispatch => {
    api.UpdateAdvisoryGroupStatus(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_ADVISORY_GROUP_STATUS,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const addMeetingDetails = (data, successHandler, errorHandler) => dispatch => {
    api.AddMeetingDetails(data).then(() => {
        dispatch({
            type: actionTypes.ADD_MEETING_DETAILS,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    });
}

export const getRosterByAgId = (agMemberId) => dispatch => {
    api.GetRosterByAgId(agMemberId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_AG_ROSTER_MEMBER_DATA,
            payload: data
        });
    });
}

// Reducer

export const initialState = {
    masterList: null,
    recruitmentList: null,
    representativeList: null,
    representativeDetails: null,
    scheduleList: null,
    applicationList: null,
    commentList: null,
    currentMeetingDetail: null,
    currentLocationDetail: null,
    currentContractDetail: null,
    meetingLocations: null,
    meetingMembers: null,
    analyticsList: null,
    viewMemberPagesData: null,
    currentMemberPortal: null,
    recruitmentMembers: null,
    memberCommentList: null,
    recruitingOutreachCommentData: null,
    recruitingOutreachData: null,
    panelSelectedMembers: null,
    panelPotentialMembers: null,
    advisoryGroupMasterList: null,
    agMemberMeetingDetails: null,
    rosterMemberDetails: null
};

export default function advisoryGroupReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_ADVISORY_GROUP_MASTER_DATA:
            return {
                ...state,
                masterList: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP__DATA:
            return {
                ...state,
                advisoryGroupMasterList: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_RECRUITMENT_DATA:
            return {
                ...state,
                recruitmentList: [...payload]
            }
        case actionTypes.GET_MEMBER_PORTAL_DATA:
            return {
                ...state,
                viewMemberPagesData: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_REPRESENTATIVE_DATA:
            return {
                ...state,
                representativeList: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_REPRESENTATIVE_DETAILS_DATA:
            return {
                ...state,
                representativeDetails: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA:
            return {
                ...state,
                applicationList: [...payload]
            }
        case actionTypes.RESET_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA:
            return {
                ...state,
                applicationList: initialState.applicationList,
            }
        case actionTypes.GET_ADVISORY_GROUP_MASTER_SCHEDULE_DATA:
            return {
                ...state,
                scheduleList: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_ANALYTICS_DATA:
            return {
                ...state,
                analyticsList: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_MEETING_DETAILS:
            return {
                ...state,
                currentMeetingDetail: payload
            }
        case actionTypes.UPDATE_ADVISORY_GROUP_MEETING_DETAILS:
            return {
                ...state,
                currentMeetingDetail: { ...payload }
            }
        case actionTypes.GET_ADVISORY_GROUP_MEETING_LOCATIONS:
            return {
                ...state,
                meetingLocations: [...payload]
            }
        case actionTypes.ARCHIVE_ADVISORY_GROUP_MEETING_LOCATION: {
            let locationsCopy = [...state.meetingLocations];
            const locationIndex = locationsCopy.findIndex(element => element.id === payload.id);
            locationsCopy.splice(locationIndex, 1);

            return {
                ...state,
                meetingLocations: [...locationsCopy],
            }
        }
        case actionTypes.GET_ADVISORY_GROUP_MEETING_MEMBERS:
            return {
                ...state,
                meetingMembers: [...payload]
            }
        case actionTypes.GET_ADVISORY_GROUP_MEETING_LOCATION:
            return {
                ...state,
                currentLocationDetail: payload
            }
        case actionTypes.GET_ADVISORY_GROUP_MEETING_LOCATION_CONTRACTS:
            return {
                ...state,
                currentContractDetail: { ...payload },
            };
        case actionTypes.RESET_LOCATION_AND_CONTRACT_DETAILS:
            return {
                ...state,
                currentLocationDetail: null,
                currentContractDetail: null,
            }
        case actionTypes.UPDATE_ADVISORY_GROUP_RECRUITMENT_MEMBER_DATA: {
            let applicationCopy = [...state.applicationList];
            const applicationIndex = applicationCopy.findIndex(element => element.id === payload.id);
            applicationCopy[applicationIndex] = { ...payload }

            return {
                ...state,
                applicationList: [...applicationCopy],
            }
        }
        case actionTypes.UPDATE_ADVISORY_GROUP_RECRUITMENT_APPLICATION_DATA: {
            let applicationCopy = [...state.applicationList];
            const applicationIndex = applicationCopy.findIndex(element => element.applicationId === payload.id);
            applicationCopy[applicationIndex] = { ...applicationCopy[applicationIndex], ...payload, applicationId: payload.id }

            return {
                ...state,
                applicationList: [...applicationCopy],
            }
        }
        case actionTypes.RESET_REPRESENTATIVE_DETAILS: {
            return {
                ...state,
                representativeDetails: initialState.representativeDetails,
            }
        }
        case actionTypes.GET_COMMENTS_BY_MEETING_ID: {
            let sortedComments = sortByDate([...payload], 'recordCreated');
            return {
                ...state,
                commentList: sortedComments
            }
        }
        case actionTypes.RESET_MEETING_MEMBER_COMMENTS: {
            return {
                ...state,
                commentList: initialState.commentList,
            }
        }
        case actionTypes.GET_ADVISORY_GROUP_PORTAL_DETAILS: {
            return {
                ...state,
                currentMemberPortal: { ...payload, portalURL: payload?.portalURL ? payload?.portalURL : null }
            }
        }
        case actionTypes.RESET_PORTAL_DETAILS: {
            return {
                ...state,
                currentMemberPortal: initialState.currentMemberPortal,
            }
        }
        case actionTypes.GET_RECRUITING_OUTREACH_DATA: {
            return {
                ...state,
                recruitingOutreachData: [...payload]
            }
        }
        case actionTypes.RECRUITING_OUTREACH_COMMENT: {
            return {
                ...state,
                recruitingOutreachCommentData: [...payload]
            }
        }
        case actionTypes.GET_MEMBER_COMMENTS: {
            return {
                ...state,
                memberCommentList: sortByDate([...payload], 'memberCommentCreatedDate'),
            };
        }
        case actionTypes.RESET_MEMBER_COMMENTS: {
            return {
                ...state,
                memberCommentList: initialState.memberCommentList,
            };
        }
        case actionTypes.RESET_RECRUITING_OUTREACH_DATA: {
            return {
                ...state,
                recruitingOutreachData: initialState.recruitingOutreachData,
            };
        }
        case actionTypes.GET_AG_SELECTED_MEMBERS: {
            return {
                ...state,
                panelSelectedMembers: [...payload],
            };
        }
        case actionTypes.GET_AG_POTENTIAL_MEMBERS: {
            return {
                ...state,
                panelPotentialMembers: [...payload],
            };
        }
        case actionTypes.SELECT_AG_MEMBERS: {
            return {
                ...state,
                panelSelectedMembers: [...state.panelSelectedMembers, ...payload.newMembers],
                panelPotentialMembers: payload.updatedPotentialMembers
            }
        }
        case actionTypes.REMOVE_AG_MEMBERS: {
            return {
                ...state,
                panelSelectedMembers: payload.updatedSelectedMembers,
                panelPotentialMembers: [...state.panelPotentialMembers, ...payload.removedMembers]
            }
        }
        case actionTypes.RESET_PANEL_SELECTION: {
            return {
                ...state,
                panelSelectedMembers: initialState.panelSelectedMembers,
                panelPotentialMembers: initialState.panelPotentialMembers
            }
        }
        case actionTypes.UPDATE_ENROLLED_MEMBER_DATA: {
            let enrolledMemberCopy = [...state.panelSelectedMembers];
            const applicationIndex = enrolledMemberCopy.findIndex(element => element.id === payload.id);
            enrolledMemberCopy[applicationIndex] = { ...enrolledMemberCopy[applicationIndex], ...payload, memberStatus: payload.memberStatus }

            return {
                ...state,
                panelSelectedMembers: [...enrolledMemberCopy],
            }
        }
        case actionTypes.GET_AG_MEMBER_MEETING_DETAILS: {
            return {
                ...state,
                memberMeetingDetails: [...payload]
            }
        }
        case actionTypes.GET_AG_MEMBER_MEETING_DATA: {
            return {
                ...state,
                agMemberMeetingDetails: [...payload]
            }
        }
        case actionTypes.GET_AG_ROSTER_MEMBER_DATA: {
            return {
                ...state,
                rosterMemberDetails: [...payload]
            }
        }
        case actionTypes.UPDATE_ROSTER_MEMBER_DATA: {
            let rosterMemberDetailsCopy = [...state.rosterMemberDetails];
            const applicationIndex = rosterMemberDetailsCopy.findIndex(element => element.id === payload.id);
            rosterMemberDetailsCopy[applicationIndex] = { ...rosterMemberDetailsCopy[applicationIndex], ...payload, memberStatus: payload.memberStatus }

            return {
                ...state,
                rosterMemberDetails: [...rosterMemberDetailsCopy],
            }
        }
        default:
            return state;
    }
}

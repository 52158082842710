import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { AlertContext } from '../../../../context/AlertContext';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import withAuth from '../../../../components/hoc/withAuth';
import ActionBar from '../../../../components/ui/ActionBar';
import NewButton from '../../../../components/ui/NewButton';
import Loader from '../../../../components/ui/Loader';
import Icon from '../../../../components/ui/Icon';
import GridButton from '../../../../components/ui/GridButton';
import DataGrid from '../../../../components/DataGrid';
import ConfirmModal from '../../../../components/ui/ConfirmModal';
import AddSupportStaffModal from './AddSupportStaffModal';
import api from '../../../../services/api/advisoryGroupService';
import messages from '../../../../utils/helper/messages';
import { ALERT_TYPE, FORM_ACTION_TYPE, SUPPORT_STAFF_MEMBER_STATUS } from '../../../../constants';
import styles from './SupportStaff.module.scss';

const SupportStaff = () => {
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [supportStaff, setSupportStaff] = useState(false);
    const [masterChecked, setMasterChecked] = useState(false);
    const [checkedIds, setCheckedIds] = useState([]);
    const [addSupportStaffModal, setAddSupportStaffModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [action, setAction] = useState(null);

    const defaultSort = [
        {
            id: 'recordModifiedDate',
            desc: true,
        },
    ];

    const hideColumn = {
        recordModifiedDate: false,
    };


    const rowActions = ({ row }) => {
        return (
            <label key={row.original.id} htmlFor={`input-${row.original.id}`}>
                <input
                    id={`input-${row.original.id}`}
                    type="checkbox"
                    checked={checkedIds.includes(row.original.id)}
                    onChange={() => handleCheckboxChange(row.original.id, event)}
                />
            </label>
        );
    };

    const rowActionHeader = () => {
        return (
            <label key={0} htmlFor="masterCheck">
                <input
                    id="masterCheck"
                    type="checkbox"
                    checked={masterChecked}
                    disabled={!supportStaff || supportStaff?.length === 0}
                    onChange={handleMasterCheckboxChange}
                />
            </label>
        );
    };

    const columns = [
        {
            id: 'select',
            header: rowActionHeader,
            cell: rowActions,
            size: 32,
            minSize: 32,
            enableSorting: false,
        },
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            sortingFn: 'text',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            sortingFn: 'text',
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
        },
        {
            id: 'cellPhone',
            header: 'Cellphone',
            accessorKey: 'cellPhone',
        },
        {
            id: 'role',
            header: 'Role',
            accessorKey: 'role',
        },
        {
            id: 'hostFlag',
            header: 'Host Flag',
            accessorKey: 'hostFlag',
            enableColumnFilter: false,
        },
        {
            id: 'specificAgShortName',
            header: 'Specific AG',
            accessorKey: 'specificAgShortName',
            accessorFn: (row) => (row.specificAgShortName === null ? 'null' : row.specificAgShortName),
            cell: ({row}) => row.original.specificAgShortName ?? '',
        },
        {
            id: 'recordModifiedDate',
            header: 'Record Modified Date',
            accessorKey: 'recordModifiedDate',
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                                onClick={() => handleEdit(row.original)}>
                        <Icon icon="edit" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="my-1" title="Archive"
                                onClick={() => handleGridArchive(row.original)}>
                        <Icon icon="archive" size={14} />
                    </GridButton>
                </>
            ),
        }
    ];

    const handleAddSupportStaffModalOpen = () => {
        setAddSupportStaffModal(true);
        setAction(FORM_ACTION_TYPE.New);
    }

    const handleAddSupportStaffModalClose = () => {
        setAddSupportStaffModal(false);
        setSelectedStaff(null);
        setAction(null);
    }

    const handleConfirm = () => {
        let staffIds = [];
        if (checkedIds.length > 0) {
            staffIds = [...checkedIds]; // multiple select
        } else if (selectedStaff?.id) {
            staffIds = [selectedStaff.id]; // grid row select
        }

        let staffUpdateData = [];
        for (let staffId of staffIds) {
            staffUpdateData.push({
                id: staffId,
                status: SUPPORT_STAFF_MEMBER_STATUS.Archived,
            });
        }

        api.UpdateSupportStaffStatuses(staffUpdateData)
            .then(() => {
                showAlert(messages.success.archiveSupportStaffMembers, ALERT_TYPE.SUCCESS);
                fetchAdvisoryGroupSupportStaff();
                handleConfirmClose();
            })
            .catch((error) => {
                showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            });
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setCheckedIds([]);
        setSelectedStaff(null);
        setMasterChecked(false);
    }

    const handleEdit = (data) => {
        setSelectedStaff(data);
        setAction(FORM_ACTION_TYPE.Edit);
        setAddSupportStaffModal(true);
    }

    const handleGridArchive = (data) => {
        setConfirmModal(true);
        setSelectedStaff(data);
    }

    const handleArchiveConfirm = () => {
        setConfirmModal(true);
    }

    const handleCheckboxChange = (staffId, event) => {
        if (event.target.checked) {
            if (!checkedIds.includes(staffId)) {
                setCheckedIds((prevIds) => [...prevIds, staffId]);
            }
        } else if (checkedIds.includes(staffId)) {
            setCheckedIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== staffId)
            );
        }
    }

    const handleMasterCheckboxChange = (event) => {
        if (event.target.checked) {
            const staffIds = supportStaff.map((staff) => staff.id);
            setCheckedIds(staffIds);
            setMasterChecked(true);
        } else {
            setCheckedIds([]);
            setMasterChecked(false);
        }
    }

    const fetchAdvisoryGroupSupportStaff = () => {
        api.GetAdvisoryGroupSupportStaff(currentUser?.companyId).then(({ data }) => {
            setSupportStaff(data);
        });
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            fetchAdvisoryGroupSupportStaff();
        }
    }, [isAppLoaded, currentUser]);

    return (
        <div className="flex-grow-1">
            <div className={`${styles.infoBox} bg-white mb-4`}>
                <h2 className="h6 fw-bold mb-1">Support Staff Role</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.</p>
                <p className="mb-0">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                    anim id est laborum.</p>
            </div>
            <ActionBar>
                <div className="d-flex gap-2">
                    <NewButton
                        onClick={handleAddSupportStaffModalOpen}
                    />
                    <Button
                        type="button"
                        onClick={handleArchiveConfirm}
                        disabled={checkedIds.length === 0}
                    >
                        Archive
                    </Button>
                </div>
            </ActionBar>

            <div>
                {supportStaff === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        data={supportStaff || []}
                        columns={columns}
                        sort={defaultSort}
                        columnDisplay={hideColumn}
                    />
                )}
            </div>

            {addSupportStaffModal && (
                <AddSupportStaffModal
                    show={addSupportStaffModal}
                    data={selectedStaff}
                    action={action}
                    onSubmit={fetchAdvisoryGroupSupportStaff}
                    onCancel={handleAddSupportStaffModalClose}
                />
            )}

            {confirmModal && (
                <ConfirmModal
                    title="Archive"
                    show={confirmModal}
                    message={messages.confirm.archiveSupportStaff}
                    onConfirm={handleConfirm}
                    onCancel={handleConfirmClose}
                    onHideCallback={handleConfirmClose}
                />
            )}
        </div>
    );
};

export default withAuth(SupportStaff);

import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import {
    ALERT_TYPE,
    ALIGN,
    MEETING_STATUS,
    POSITIONS,
    PROGRAM_MEETING_TYPE,
    VALIDATIONS
} from "../../../../constants";
import { optionValues, isObjectEmpty, todayStartofDay, toDate } from "../../../../utils/utils";
import { AlertContext } from "../../../../context/AlertContext";
import { addMeetingDetails } from "../../../../ducks/advisoryGroup";
import messages from "../../../../utils/helper/messages";

const NewMeetingModal = ({ show, onCancel, data }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);
    const meetingTypeOptions = optionValues(PROGRAM_MEETING_TYPE);

    const [isLoading, setIsLoading] = useState(false);
    const [meetingDetails, setMeetingDetails] = useState(null);
    const [ownerOptions, setOwnerOptions] = useState(null);

    const companyUsers = useSelector((state) => state.company?.users);

    useEffect(() => {
        if (companyUsers && companyUsers.length > 0) {
            setOwnerOptions(companyUsers.map(user => ({ id: user.id, value: user.name })))
        }
    }, [companyUsers]);

    const arr = [
        {
            key: 'shortName',
            labelName: 'AG Short Name',
            placeholderName: 'AG Short Name',
            type: 'text',
            disabled: true,
            required: false
        },
        {
            key: 'title',
            labelName: 'Meeting Title',
            placeholderName: 'Meeting Title',
            type: 'text',
            maxLength: 15,
            valid: VALIDATIONS.String.isTitle
        },
        {
            key: 'meetingDate',
            labelName: 'Meeting Date',
            placeholderName: 'Meeting Date',
            type: 'date',
            minValue: todayStartofDay()
        },
        {
            key: 'type',
            labelName: 'Meeting Type',
            placeholderName: 'Select Meeting Type',
            type: 'options',
            optionValues: meetingTypeOptions,
        },
        {
            key: 'ownerId',
            labelName: 'Meeting Owner',
            placeholderName: 'Meeting Owner',
            type: 'options',
            optionValues: ownerOptions,
            required: false
        }
    ];

    const handleSubmit = async (formData) => {
        setIsLoading(true);
        const { id, companyId } = data;

        const submitData = {
            agId: id,
            title:formData.title,
            meetingDate: toDate(formData.meetingDate),
            type: formData.type,
            owner: formData.ownerId,
            status: MEETING_STATUS.P,
            companyId: companyId
        }
        dispatch(addMeetingDetails(submitData, handleSuccess, handleError));
        setIsLoading(false);
    };

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.AddMeeting, ALERT_TYPE.SUCCESS);
        onCancel();
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleMeetingDetailsChange = (update) => {
        setMeetingDetails((details) => ({ ...details, ...update }));
    };

    const handleInputChange = (name, value) => {
        handleMeetingDetailsChange({ [name]: value });
    };

    const isValidMeetingInfo = () => {
        return !isObjectEmpty(meetingDetails);
    };

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>New Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        loading={isLoading}
                        disabled={!isValidMeetingInfo()}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default NewMeetingModal;

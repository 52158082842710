import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { AlertContext } from '../../../../context/AlertContext';
import SharedForm from '../../../../components/ui/Form';
import {
    AG_STATUS,
    ALERT_TYPE,
    ALIGN, FORM_ACTION_TYPE,
    POSITIONS,
    SUPPORT_STAFF_ROLE,
    VALIDATIONS,
    YES_NO_INVERSE,
} from '../../../../constants';
import messages from '../../../../utils/helper/messages';
import api from '../../../../services/api/advisoryGroupService';
import { dropdownValues, isObjectEmpty, optionValues, pickProps, radioOptionValues } from '../../../../utils/utils';
import { getAdvisoryGroupData } from '../../../../ducks/advisoryGroup';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';

const AddSupportStaffModal = ({ show, data = null, action, onSubmit, onCancel }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);
    const advisoryGroups = useSelector((state) => state.advisoryGroup.masterList);

    const [staffDetails, setStaffDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [agList, setAgList] = useState(null);
    const [agOptions, setAgOptions] = useState(null);

    const handleSubmit = (formData) => {
        setIsLoading(true);
        if (action === FORM_ACTION_TYPE.New) {
            const addData = {
                ...formData,
                companyId: currentUser?.companyId,
                specificAg: formData.agId !== '' ? YES_NO_INVERSE.Yes : YES_NO_INVERSE.No,
                agId: formData.agId !== '' ? formData.agId : null
            };
            api.AddAdvisoryGroupSupportStaff(addData)
                .then(handleSuccess)
                .catch(handleError);
        } else if (action === FORM_ACTION_TYPE.Edit) {
            const updateData = {
                ...formData,
                id: data.id,
                companyId: data.companyId,
                specificAg: formData.agId !== '' ? YES_NO_INVERSE.Yes : YES_NO_INVERSE.No,
                agId: formData.agId !== '' ? formData.agId : null
            }
            api.UpdateAdvisoryGroupSupportStaff(updateData)
                .then(handleSuccess)
                .catch(handleError);
        }
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(
            action === FORM_ACTION_TYPE.New
                ? messages.success.addSupportStaff
                : messages.success.updateSupportStaff,
            ALERT_TYPE.SUCCESS
        );
        onSubmit();
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleModalHide = () => {
        onCancel();
    }

    const isValidSupportStaffInfo = () => {
        if (action === FORM_ACTION_TYPE.Edit) {
            return !isObjectEmpty(staffDetails);
        }
        return true
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }
    }, [isAppLoaded, dispatch, currentUser]);

    useEffect(() => {
        if (advisoryGroups?.length) {
            const filteredAgs = advisoryGroups.filter(ag => ag.status === AG_STATUS.Active || ag.status === AG_STATUS.New);
            setAgList(filteredAgs);
        }
    }, [advisoryGroups]);

    useEffect(() => {
        if (agList && agList.length > 0) {
            setAgOptions(dropdownValues(agList, 'shortName', undefined, true, 'Select AG'));
        }
    }, [agList]);

    useEffect(() => {
        if (data) {
            const editData = pickProps(data, [
                'firstName', 'lastName', 'organization', 'email', 'cellPhone', 'role', 'hostFlag', 'agId'
            ]);
            setStaffDetails({
                ...editData,
                agId: editData.agId === null ? '' : editData.agId,
            });
        }
    }, [data]);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'organization',
            labelName: 'Organization',
            type: 'text',
            maxLength: 35,
        },
        {
            key: 'email',
            labelName: 'Email',
            type: 'email',
            maxLength: 255,
        },
        {
            key: 'cellPhone',
            labelName: 'Cellphone',
            type: 'tel',
            maxLength: 10,
        },
        {
            key: 'role',
            labelName: 'Role',
            type: 'options',
            optionValues: optionValues(SUPPORT_STAFF_ROLE, true, 'Select Role'),
            maxLength: 12,
        },
        {
            key: 'hostFlag',
            labelName: 'Host Flag',
            type: 'radio',
            optionValues: radioOptionValues(YES_NO_INVERSE, true),
            maxLength: 12,
        },
        {
            key: 'agId',
            labelName: 'Specific AG',
            type: 'options',
            optionValues: agOptions,
            required: false
        }
    ];

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleModalHide}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>{action === FORM_ACTION_TYPE.New ? 'New' : 'Edit'} Support Staff Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={staffDetails}
                        loading={isLoading}
                        onSubmit={handleSubmit}
                        onCancel={handleModalHide}
                        disabled={!isValidSupportStaffInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddSupportStaffModal;

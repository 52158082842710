import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import withAuth from "../../../components/hoc/withAuth";
import Loader from "../../../components/ui/Loader";
import DataGrid from "../../../components/DataGrid";
import { getAdvisoryGroupRepresentativeneData } from "../../../ducks/advisoryGroup";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import OverflowText from "../../../components/ui/OverflowTooltip";
import styles from "./Representativeness.module.scss";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import { PROGRAM_TYPE } from "../../../constants";
import RepresentativenessDetailsModal from "./../RepresentativenessDetailsModal";
import GridCellRating from "../../../components/ui/GridCellRating";


const Representativeness = () => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const repsData = useSelector((state) => state.advisoryGroup.representativeList);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [detailsModal, setDetailsModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const RowActions = ({ row }) => (
        <>
            <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                        onClick={() => handleDetailsModalOpen(row.original.id)}>
                <Icon icon="view" size={14} />
            </GridButton>
            <GridButton variant="light" size="sm" className="me-1 my-1" title="Panel Select">
                <Icon icon="panel-selection" size={21} />
            </GridButton>
            <GridButton variant="light" size="sm" className="me-1 my-1" title="Participation">
                <Icon icon="participation" size={15} />
            </GridButton>
        </>
    )

    const columns = [
        {
            id: 'shortName',
            header: 'AG Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
            maxSize: 300,
            sortingFn: 'text',
        },
        {
            id: 'type',
            header: 'Market EAC',
            accessorKey: 'type',
            accessorFn: (row) => PROGRAM_TYPE[row.type],
            enableSorting: false,
        },
        {
            id: 'selectionScore',
            header: 'Selection Representativeness Score',
            accessorKey: 'selectionScore',
            cell: ({ row }) => <GridCellRating rating={row.original.selectionScore} customClass="my-1" />,
            enableSorting: false,
        },
        {
            id: 'currentParticipationScore',
            header: 'Current Participation Representativeness Score',
            accessorKey: 'currentParticipationScore',
            cell: ({ row }) => <GridCellRating rating={row.original.currentParticipationScore} customClass="my-1" />,
            enableSorting: false,
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            enableColumnFilter: false,
            cell: RowActions,
        },
    ];

    const handleDetailsModalOpen = (id) => {
        setSelectedId(id);
        setDetailsModal(true);
    }

    const handleDetailsModalClose = () => {
        setSelectedId(null);
        setDetailsModal(false);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupRepresentativeneData(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser]);

    return (
        <div className="flex-grow-1">
            <Row>
                <Col md={4}>
                    <div className={`${styles.infoBox} px-3 py-4`}>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </Col>
                <Col md={8}>
                    <div className="flex-grow-1">
                        {repsData === null ? (
                            <Loader />
                        ) : (
                            <DataGrid
                                columns={columns}
                                data={repsData}
                                enableFilters={false}
                            />
                        )}
                    </div>
                </Col>
            </Row>

            {detailsModal &&
                <RepresentativenessDetailsModal
                    show={detailsModal}
                    id={selectedId}
                    onCancel={handleDetailsModalClose}
                />
            }
        </div>
    )
}

export default withAuth(Representativeness);

import { useState } from "react";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { Button } from "react-bootstrap";
import styles from './PdfViewer.module.scss';

const PdfViewer = (props) => {
    const pageClass = props.pageClass ? props.pageClass : '';
    const documentClass = props.documentClass ? props.documentClass : '';
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(
            pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
        );

    return (
        <div className={styles.pdfViewer}>
            <div className={styles.inner}>
                {props.isPreview ? <></> : <nav className={`bg-light p-2 d-flex align-items-center justify-content-between`}>
                    <Button type="button"
                        variant="outline-primary"
                        size="sm"
                        className="text-uppercase"
                        disabled={pageNumber === 1}
                        onClick={goToPrevPage}
                    >
                        Prev
                    </Button>
                    <p className="mb-0">Page {pageNumber} of {numPages}</p>
                    <Button type="button"
                        variant="outline-primary"
                        size="sm"
                        className="text-uppercase"
                        disabled={pageNumber === numPages}
                        onClick={goToNextPage}
                    >
                        Next
                    </Button>
                </nav>}
                <Document
                    file={props.file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={documentClass}
                >
                    <Page pageNumber={pageNumber} renderTextLayer={false} className={pageClass} />
                </Document>
            </div>
        </div>
    );
}

export default PdfViewer;

import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { getAdvisoryGroupAnalyticsData } from '../../../ducks/advisoryGroup';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import DataGrid from '../../../components/DataGrid';
import withAuth from '../../../components/hoc/withAuth';
import Loader from '../../../components/ui/Loader';
import NavigationBoxDashboard from '../../../components/ui/NavigationBoxDashboard';
import OverflowText from "../../../components/ui/OverflowTooltip";
import { PROGRAM_TYPE, AGGREGATE_SCORES, AGGREGATE_SCORES_COLOR } from "../../../constants";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";

const Analytics = () => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const analyticsData = useSelector((state) => state.advisoryGroup.analyticsList);
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupAnalyticsData(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    const NavContent = [
        {
            path: "/advisory-group-management/analytics/population-characterization",
            locationName: "Advisory Group Analytics",
            pageName: 'Population Characterization',
            icon: 'population-characterization',
            classname: "purple",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/analytics/representativeness",
            locationName: "Advisory Group Analytics",
            pageName: 'Advisory Group Representativeness',
            icon: 'advisory-group-representatives',
            classname: "yellow",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/analytics/healthEquity",
            locationName: "Advisory Group Analytics",
            pageName: 'Health Equity',
            icon: 'health-equity',
            classname: "darkSalmon",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
        {
            path: "/advisory-group-management/analytics/cahps-assessment",
            locationName: "Advisory Group Analytics",
            pageName: 'CAHPS Assessment',
            icon: 'market-assment',
            classname: "gsApple",
            description: "Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore."
        },
    ]

    const columns = [
        {
            id: 'shortName',
            header: 'Advisory Group Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
            maxSize: 300,
            sortingFn: 'text'
        },
        {
            id: 'type',
            header: 'Advisory Group Type',
            accessorKey: 'type',
            accessorFn: (originalRow) => PROGRAM_TYPE[originalRow.type],
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_TYPE[row.original.type]}
                </OverflowText>
            ),
            sortingFn: 'text'
        },
        {
            id: 'aggregateScore',
            header: 'Representativeness Score',
            accessorKey: 'aggregateScore',
            accessorFn: (originalRow) => AGGREGATE_SCORES[originalRow.aggregateScore],
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    <div className={`px-2 ${AGGREGATE_SCORES_COLOR[row.original.aggregateScore]}`}>
                        {AGGREGATE_SCORES[row.original.aggregateScore]}
                    </div>
                </OverflowText>
            ),
            sortingFn: 'text',
            filterFn: 'equalsString',
        },
        {
            id: 'targetSize',
            header: 'Health Equity Score',
            accessorKey: 'targetSize',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.targetSize}
                </OverflowText>
            ),
        },
        {
            id: 'targetSize',
            header: 'CAHPS Assessment Score',
            accessorKey: 'targetSize',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.targetSize}
                </OverflowText>
            ),
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 120,
            cell: () => (
                <GridButton variant="light" size="sm" className="me-1 my-1" title="View">
                    <Icon icon="view" size={16} />
                </GridButton>
            )
        },
    ];

    const defaultSort = [{
        id: 'shortName',
        desc: false
    }];

    return (
        <Row className="h-100 mb-4">
            <Row className="w-100 mb-4">
                <Col
                    className={`d-flex px-4`}
                    md={6}>
                    <NavigationBoxDashboard data={NavContent} />
                </Col>
                <Col
                    className="d-flex flex-column px-4"
                    md={6}>
                    {analyticsData === null ? <Loader /> : (
                        <div>
                            <DataGrid
                                columns={columns}
                                data={analyticsData}
                                sort={defaultSort}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </Row>
    );
};

export default withAuth(Analytics);

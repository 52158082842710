import { Nav } from 'react-bootstrap';
import NavigationBox from './NavigationBox';

export const NavigationBoxDashboard = ({ data }) => {
    return (
        <Nav className={`flex-column flex-grow-1 gap-3`}>
            { data?.map((navBoxItem) => {
                return <NavigationBox
                    key={navBoxItem.path}
                    type={navBoxItem.type}
                    onModalOpen={navBoxItem.onModalOpen}
                    path={navBoxItem.path}
                    locationName={navBoxItem.locationName}
                    pageName={navBoxItem.pageName}
                    icon={navBoxItem.icon}
                    classname={navBoxItem.classname}>
                    {navBoxItem.description}
                </NavigationBox>
            })}
        </Nav>
    );
};

export default NavigationBoxDashboard;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SharedForm from "../../../components/ui/Form";
import FileProgress from "../../../components/ui/FileProgress";
import withAuth from "../../../components/hoc/withAuth";
import {
    getContent,
    createContent,
    getContentFile,
    updateContent
} from "../../../ducks/content";
import messages from "../../../utils/helper/messages";
import { getFileExtension, isObjectEmpty, isValidName, optionValues } from "../../../utils/utils";
import {
    ALERT_TYPE,
    ALIGN,
    AUDIENCE_APPLICATION,
    AUDIENCE_SEGMENT,
    CMS_CONTENT_FORMAT,
    CMS_CONTENT_TYPE,
    MIME_TYPES,
    POSITIONS
} from "../../../constants";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import { AlertContext } from "../../../context/AlertContext";
import Loader from "../../../components/ui/Loader";

const AddContentModal = ({ show, onCancel, content }) => {
    const id = content?.id;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [contentDetails, setContentDetails] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const { contents } = useSelector((state) => state.content);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(null);

    const contentTypeOptions = optionValues(CMS_CONTENT_TYPE);
    const segmentOptions = optionValues(AUDIENCE_SEGMENT);
    const applicationOptions = optionValues(AUDIENCE_APPLICATION);
    const fileFormatOptions = optionValues(CMS_CONTENT_FORMAT);

    const arr = [
        { key: 'type', labelName: 'Type', placeholderName: 'Type', type: 'options', optionValues: contentTypeOptions },
        { key: 'title', labelName: 'Title', placeholderName: 'Title', type: 'text', maxLength: 100 },
        { key: 'audienceSegment', labelName: 'Audience Segment', placeholderName: 'Audience Segment', type: 'options', optionValues: segmentOptions },
        { key: 'audienceApplication', labelName: 'Audience Application', placeholderName: 'Audience Application', type: 'options', optionValues: applicationOptions },
        { key: 'format', labelName: 'Format', placeholderName: 'File Format', type: 'options', optionValues: fileFormatOptions, disabled: true },
        {
            key: 'file', labelName: 'Content', placeholderName: 'Upload File', type: 'file',
            allowedTypes: [MIME_TYPES.PDF, MIME_TYPES.JPG, MIME_TYPES.HTML], fileNameKey: 'fileName', maxLength: 50
        },
    ];

    const contentSchema = {
        id: -1,
        title: "",
        type: 'B',
        audienceSegment: 'A',
        audienceApplication: 'A',
        audienceUser: 'A',
        format: 'P',
        fileName: "",
        file: null,
        recordStatus: 'O',
    }

    const handleSuccess = () => {
        showAlert(id ? messages.success.updateContent : messages.success.createContent, ALERT_TYPE.SUCCESS);
        setIsLoading(false);
        setProgress(0);
        onHideCallback();
        if (!id) {
            dispatch(getContent());
        }
    }

    const handleError = (error) => {
        setIsLoading(false);
        setProgress(0)
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleUpload = (event) => {
        progressRef.current = Math.round(
            (100 * event.loaded) / event.total
        );
        setProgress(progressRef.current);
    }

    const Submit = () => {
        setIsLoading(true);
        try {
            if (id) {
                dispatch(
                    updateContent(contentDetails, handleUpload, handleSuccess, handleError)
                );
            } else {
                dispatch(
                    createContent(contentDetails, handleUpload, handleSuccess, handleError)
                );
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleContentChange = (update) => {
        setContentDetails((details) => ({ ...details, ...update }))
    }

    const onHideCallback = () => {
        setContentDetails(null);
        onCancel();
    }

    const inputChange = (name, value) => {
        if (name === "file") {
            let fileExtension = getFileExtension(value.name).toUpperCase();
            fileExtension = (fileExtension === 'JPG' ? 'JPEG' : fileExtension);
            handleContentChange({
                fileName: value.name,
                file: value,
                format: Object.keys(CMS_CONTENT_FORMAT).find(
                    (type) => CMS_CONTENT_FORMAT[type] === fileExtension
                ),
            });
            setFile(value);
        } else {
            handleContentChange({ [name]: value });
        }
    }

    const isValidContentInfo = () => {
        if (isObjectEmpty(contentDetails)) {
            return false;
        }

        return isValidName(contentDetails.title) && (contentDetails.fileName || contentDetails.file);
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (id) {
                setIsContentLoading(true);
                dispatch(getContentFile(id,
                    () => setIsContentLoading(false),
                    () => setIsContentLoading(false)
                ));
            } else {
                setContentDetails({
                    ...contentSchema,
                    createUserId: currentUser?.id,
                    updateUserId: currentUser?.id,
                });
            }
        }
    }, [isAppLoaded, dispatch, id, show]);

    useEffect(() => {
        if (contents?.length) {
            let content = contents.find((c) => c.id === parseInt(id));
            content && handleContentChange({ ...content });
        }
    }, [contents]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>{id ? 'Edit' : 'New'} Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2 position-relative">
                    {isContentLoading && <Loader />}
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={contentDetails}
                        onSubmit={Submit}
                        onCancel={onHideCallback}
                        onInputChanged={inputChange}
                        loading={isLoading}
                        disabled={!isValidContentInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={id ? 'Update' : 'Submit'}
                    />
                    {progress > 0 && (
                        <FileProgress
                            file={file}
                            progress={progress}
                            wrapperStyle="mt-2"
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withAuth(AddContentModal);

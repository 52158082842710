import { useContext, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import isEqual from 'lodash/isEqual';
import LoadingButton from "../../../../components/ui/LoadingButton";
import styles from "./PreMeetingResponseModal.module.scss";
import { useDispatch } from "react-redux";
import {
    addAttendanceLogs,
    updatePreResponseStatus
} from "../../../../ducks/advisoryGroup";
import { AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE, ALERT_TYPE } from "../../../../constants";
import messages from "../../../../utils/helper/messages";
import { AlertContext } from "../../../../context/AlertContext";


const PreMeetingResponseModal = ({ show, onCancel, data, info }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [checkboxes, setCheckboxes] = useState({});
    const [initialCheckboxes, setInitialCheckboxes] = useState({});

    const initializeCheckboxes = () => {
        const checks = {};
        data.forEach((attendee) => {
            checks[attendee.id] = attendee.preMeetingResponse;
        });
        setCheckboxes(checks);
        setInitialCheckboxes(checks);
    }

    const handleInputChange = (id, preMeetingResponse) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [id]: preMeetingResponse,
        }));
    }

    const handleSuccess = () => {
        setLoading(false);
        showAlert(messages.success.updateMeetingPreResponseStatus, ALERT_TYPE.SUCCESS);
        onCancel();
    }

    const handleError = (error) => {
        setLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);

        let statusUpdate = [];
        let statusCreate = [];

        for (let item in checkboxes) {
            if (checkboxes[item] !== initialCheckboxes[item]) {
                let recordToUpdate = data.find(rec => {
                    return rec.id === parseInt(item) && rec.attendanceLogId !== null
                });

                let recordToCreate = data.find(rec => {
                    return rec.id === parseInt(item) && rec.attendanceLogId === null
                });

                if (recordToUpdate) {
                    statusUpdate.push({
                        attendanceLogId: recordToUpdate.attendanceLogId,
                        status: checkboxes[item]
                    });
                }

                if (recordToCreate) {
                    statusCreate.push({
                        ...info,
                        agMemberId: recordToCreate.id,
                        statusPost: recordToCreate.meetingParticipation,
                        status: checkboxes[item],
                        locationId: recordToCreate.locationId,
                        accommodationsRequested: recordToCreate.accommodationsRequested,
                    });
                }

            }
        }

        if (statusUpdate.length) {
            dispatch(updatePreResponseStatus(statusUpdate, handleSuccess));
        }
        if (statusCreate.length) {
            dispatch(addAttendanceLogs(statusCreate, handleSuccess, handleError));
        }
    }

    const isDataUpdated = () => {
        return !(isEqual(checkboxes, initialCheckboxes))
    }

    useEffect(() => {
        data && initializeCheckboxes();
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            dialogClassName={styles.modalContainer}
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Pre-Meeting Response</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="text-end mb-4">
                            <Button variant="outline-primary" type="button" className="me-2" onClick={onCancel}>Cancel</Button>
                            <LoadingButton type="submit" loading={loading} disabled={loading || !isDataUpdated()}>Submit</LoadingButton>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="flex-grow-1 fw-bold ps-2 pe-3">Member</div>
                            <div className={`flex-shrink-0 fw-bold pe-2 text-center ${styles.colNoResponse}`}>No Response</div>
                            <div className={`flex-shrink-0 fw-bold pe-2 text-center ${styles.colDeclined}`}>Declined</div>
                            <div className={`flex-shrink-0 fw-bold pe-2 text-center ${styles.colConfirmed}`}>Confirmed</div>
                            <div className={`flex-shrink-0 fw-bold px-2 ${styles.colDateModified}`}>Date/Time</div>
                        </div>
                        <div>
                            {
                                data?.map((item) => (
                                    <div key={item.id} className="d-flex mb-1 py-1 rounded-2 hover-highlight">
                                        <div className="flex-grow-1 ps-2 pe-3">{item.name}</div>
                                        <div className={`flex-shrink-0 pe-2 text-center ${styles.colNoResponse}`}>
                                            {
                                                <Form.Check
                                                    type="checkbox"
                                                    name={`nor_${item.id}`}
                                                    value={item.preMeetingResponse}
                                                    id={`nor_${item.id}`}
                                                    checked={checkboxes[item.id] === AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.NoResponse || false}
                                                    onChange={() => handleInputChange(item.id, AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.NoResponse)}
                                                />
                                            }
                                        </div>
                                        <div className={`flex-shrink-0 px-2 text-center ${styles.colDeclined}`}>
                                            {
                                                <Form.Check
                                                    type="checkbox"
                                                    name={item.name}
                                                    value={item.preMeetingResponse}
                                                    id={`dec_${item.id}`}
                                                    checked={checkboxes[item.id] === AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.Declined || false}
                                                    onChange={() => handleInputChange(item.id, AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.Declined)}
                                                />
                                            }
                                        </div>
                                        <div className={`flex-shrink-0 px-2 text-center ${styles.colConfirmed}`}>
                                            {
                                                <Form.Check
                                                    type="checkbox"
                                                    name={item.name}
                                                    value={item.preMeetingResponse}
                                                    id={`cnf_${item.id}`}
                                                    checked={checkboxes[item.id] === AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.Confirmed || false}
                                                    onChange={() => handleInputChange(item.id, AG_MEETING_ATTENDANCE_STATUS_PRE_INVERSE.Confirmed)}
                                                />
                                            }
                                        </div>
                                        <div className={`flex-shrink-0 px-2 small ${styles.colDateModified}`}>{item.recordModified}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PreMeetingResponseModal;
